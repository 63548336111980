<template>
  <FormModal
    :next-btn-text="buttonTitle"
    show-back-btn
    title="Vul je gegevens aan"
  >
    <template #body>
      <ContactInformationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Je gegevens',
      headDescription:
        'Vul je gegevens aan, zodat we je in contact kunnen brengen met de best geschikte dakkapel leveranciers uit jouw regio.',
      path: '/offertes-aanvragen/je-gegevens',
      progressValue: 90,
      checkoutStep: 6,
      experiment: null,
      buttonTitle: 'Vergelijk nu',
    }
  },

  /**
   * @returns {void}
   */
  async beforeMount() {
    /**
     * https://bambelo.absmartly.com/experiments/333
     */
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD2-56',
      source: ExperimentSource.ABSMARTLY,
    })

    if (this.experiment?.isTreatment) {
      this.buttonTitle = 'Ik ga vergelijken'
    }
  },
}
</script>
